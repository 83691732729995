import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="scroll"
export default class extends Controller {
  static targets = ["scrollable"];

  sync(e) {
    this.scrollableTargets.forEach((target) => {
      target.scrollLeft = e.target.scrollLeft;
    });
  }
}
