import { Controller } from "@hotwired/stimulus";
import { convertTimeToNumber } from "../utils/convert_time_to_number";

// Connects to data-controller="hours-change-form"
export default class extends Controller {
  static targets = ["hours", "time"];
  convert() {
    this.hoursTarget.value = convertTimeToNumber(this.timeTarget.value);
  }
}
