import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="flash-message"
export default class extends Controller {
  static values = { timer: Number };

  connect() {
    setTimeout(() => {
      this.element.setAttribute("open", true);
    }, 0);
    this.timerValue = setTimeout(() => {
      this.disapear();
    }, 5000);
  }

  close() {
    clearTimeout(this.timerValue);
    this.disapear();
  }

  disapear() {
    this.element.removeAttribute("open");
    setTimeout(() => {
      this.element.remove();
    }, 500);
  }
}
