import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="form"
export default class extends Controller {
  connect() {
    this.element.querySelectorAll("select").forEach((select) => {
      const selected = select.querySelector("[selected]");
      select.selectedIndex = selected?.index || 0;
    });
  }

  clearOthers(event) {
    const others = event.target.dataset.others.split(" ");

    others.forEach((other) => {
      const otherElement = this.element[other];

      if (otherElement) {
        otherElement.value = null;
      }
    });
  }

  debounceSubmit() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.submit();
    }, 500);
  }

  submit() {
    this.element.requestSubmit();
  }
}
