import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="counter"
export default class extends Controller {
  static values = { count: Number };
  static targets = ["counter", "data"];

  countValueChanged() {
    this.counterTarget.textContent = this.countValue;
  }

  dataTargetConnected(target) {
    switch (target.dataset.counterAction) {
      case "increase":
        this.countValue += +target.dataset.amount;
        break;
      case "decrease":
        this.countValue -= +target.dataset.amount;
        break;
      case "reset":
        this.countValue = +target.dataset.amount;
        break;
    }
  }
}
