import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["close"];

  connect() {
    this.dialog = this.element.querySelector("dialog");
  }

  closeTargetConnected() {
    this.close();
  }

  open(e) {
    this.dialog.showModal();
  }

  close(e) {
    this.dialog.close();
  }
}
