import Form from "./form_controller";

// Connects to data-controller="worker-form"
export default class extends Form {
  static targets = ["step", "healthSystemContributionType"];

  connect() {
    super.connect();
    this.openCurrentStep();
  }

  back() {
    this.findCurrentStep().removeAttribute("open");
    const step = +this.element.worker_step.value;
    this.element.worker_step.value = step - 1;
    this.openCurrentStep();
  }

  skip() {
    this.findCurrentStep().removeAttribute("open");
    const step = +this.element.worker_step.value;
    this.element.worker_step.value = step + 1;
    this.openCurrentStep();
  }

  findCurrentStep() {
    return this.stepTargets.find(
      (step) => step.dataset.step === this.element.worker_step.value
    );
  }

  openCurrentStep() {
    this.findCurrentStep().setAttribute("open", true);
  }

  restrictContribution(event) {
    const dependents = this.healthSystemContributionTypeTargets;

    if (event.target.value === "FONASA") {
      dependents.forEach((checkbox) => {
        if (checkbox.value === "true") {
          checkbox.closest("label").classList.add("hidden");
        }

        checkbox.checked = checkbox.value === "false";
        checkbox.dispatchEvent(new Event("input"));
      });
    } else {
      dependents.forEach((checkbox) => {
        checkbox.closest("label").classList.remove("hidden");
        checkbox.checked = false;
        checkbox.dispatchEvent(new Event("input"));
      });
    }
  }
}
