import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="nested-form"
export default class extends Controller {
  static targets = ["template", "deleteButton"];
  static values = { index: Number };

  connect() {
    this.indexValue = this.templateTargets.length - 1;

    // from the second element, show the delete button
    this.deleteButtonTargets.slice(1).forEach((button) => {
      button.classList.remove("hidden");
    });
  }

  addElement() {
    const last = this.templateTargets[this.templateTargets.length - 1];
    const element = last.cloneNode(true);
    const last_button = last.querySelector("button");
    last_button.classList.remove("hidden");
    last.before(element);

    this.indexValue += 1;
    last.querySelectorAll("input").forEach((input) => {
      input.value = "";
      input.name = input.name.replace(/\[\d+\]/, `[${this.indexValue}]`);
      input.id = input.id.replace(/\_\d+\_/, `_${this.indexValue}_`);
    });
  }

  removeElement(event) {
    const element = event.target.closest(
      "[data-nested-form-target='template']"
    );
    element.classList.add("hidden");
    element.querySelector("input[name*='_destroy']").value = 1;
  }
}
