import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="notifications"
export default class extends Controller {
  static targets = ["details"];

  connect() {
    setInterval(() => {
      if (this.detailsTarget.hasAttribute("open")) return;

      const src = this.element.dataset.fetchFrom;
      this.element.setAttribute("src", src);
    }, 10000);
  }
}
