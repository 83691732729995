import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  password(e) {
    const inputType = this.inputTarget.type;

    this.inputTarget.type = inputType === "password" ? "text" : "password";
  }
}
