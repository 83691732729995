import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["sidebar", "main"];

  toggle(e) {
    this.sidebarTarget.toggleAttribute("open");
    this.mainTarget.toggleAttribute("open");
  }
}
