import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="submit-on-select"
export default class extends Controller {
  static targets = ["select"];

  // Workaround for the select to display the correct option after a page refresh
  connect() {
    const selected = this.selectTarget.querySelector("[selected]");
    this.selectTarget.selectedIndex = selected?.index || 0;
  }

  submit() {
    this.element.requestSubmit();
  }
}
