import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { step: Number };

  connect() {
    super.connect();
  }

  stepValueChanged() {
    this.element.dataset.step = this.stepValue;
    this.element.current_step.value = this.stepValue;
  }

  goToStep(event) {
    event.preventDefault();
    const params = new URL(event.target.href).searchParams;
    const step = +params.get("step");
    this.stepValue = step;
  }
}
