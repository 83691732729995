import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  print(event) {
    event.preventDefault();

    document.querySelector("aside").removeAttribute("open");
    document.querySelector("main").removeAttribute("open");

    setTimeout(() => {
      window.print();
    }, 500);
  }

  alertHorizontal(event) {
    event.preventDefault();

    window.alert("Imprima en esta pagina en horizontal");
  }
}
