import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="search"
export default class extends Controller {
  static targets = ["query"];
  static values = { timer: Number };

  connect() {
    const params = new URLSearchParams(window.location.search);
    this.queryTarget.value = params.get(this.queryTarget.id);
  }

  submit() {
    clearTimeout(this.timerValue);
    this.timerValue = setTimeout(() => {
      this.element.requestSubmit();
    }, 500);
  }
}
