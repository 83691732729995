import { Controller } from "@hotwired/stimulus";
import { convertTimeToNumber } from "../utils/convert_time_to_number";

// Connects to data-controller="multiple-forms"
export default class extends Controller {
  static targets = [
    "form",
    "worker",
    "promptMissing",
    "openModalButton",
    "bigForm",
  ];

  connect() {
    setTimeout(() => {
      const withoutValues = this.missingAttendanceTypes();
      const missing = this.workerTargets.filter((worker) =>
        withoutValues.includes(worker.dataset.workerId)
      );
      if (missing.length > 0) {
        missing.forEach((worker) => {
          this.promptMissingTarget.append(worker.cloneNode(true));
        });
      } else {
        this.changeButton();
      }
    }, 0);

    this.element.addEventListener("invalid", (event) => {
      console.log(event.target);
    });
  }

  removeMissingWorker({ detail: { worker } }) {
    const child = this.promptMissingTarget.querySelector(
      `[data-worker-id="${worker}"]`
    );
    child.remove();
    if (this.promptMissingTarget.children.length == 0) {
      this.changeButton();
    }
  }

  changeButton() {
    this.openModalButtonTarget.dataset.action = "multiple-forms#submitAll";
  }

  submitAll() {
    const data = [];
    // use for loop to cancel the submit if any field is invalid
    for (let i = 0; i < this.formTargets.length; i++) {
      const form = this.formTargets[i];
      // Get the first invalid field, focus it and cancel the submit if any
      const firstInvalid = form.querySelector(":invalid");
      if (firstInvalid) {
        firstInvalid.reportValidity();
        firstInvalid.focus();
        return; // cancel the submit
      }
      // Get the data from the form
      const {
        attendance_attendance_type_id,
        attendance_extra_hours,
        attendance_discount_hours,
        attendance_comments,
      } = form;
      // Create the object to push to the data array
      const formData = {
        attendance_id: form.dataset.attendanceId,
        attendance_type_id: attendance_attendance_type_id.value,
        extra_hours: convertTimeToNumber(attendance_extra_hours.value),
        discount_hours: convertTimeToNumber(attendance_discount_hours.value),
        comments: attendance_comments.value,
      };
      data.push(formData);
    }

    this.bigFormTarget.data.value = JSON.stringify(data);
    this.bigFormTarget.requestSubmit();
  }

  missingAttendanceTypes() {
    return this.formTargets
      .filter((form) => !form.querySelector("select").value)
      .map((form) => form.dataset.worker);
  }
}
