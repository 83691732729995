import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="calendar"
export default class extends Controller {
  static targets = [
    "simpleAttendance",
    "coloredAttendance",
    "select",
    "title",
    "count",
    "attendancesContainer",
  ];

  initialize() {
    this.editListener = this.editListener.bind(this);
  }

  connect() {
    this.selectTarget.selectedIndex = 0;
    this.displayAttendance("content");
    this.displayTitle("attendance");
    this.displayCount("attendance");
  }

  editListener(event) {
    // Event delegation, we search for the container with the attribute data-start-date] that will not be present if there is no attendance created for that day
    const range = event.target.closest("[data-start-date]");
    if (!range) return;

    const container = range.closest("[data-worker]");
    let dialog;
    if (this.selectTarget.value === "Asistencia diaria") {
      dialog = document.querySelector("#attendance_change_request_modal");
      const form = dialog.querySelector("form");
      form.attendance_change_request_start_date.value = range.dataset.startDate;
      form.attendance_change_request_end_date.value = range.dataset.endDate;
      form.attendance_change_request_worker_id.value = container.dataset.worker;
    } else {
      dialog = document.querySelector("#hours_change_request_modal");
      const form = dialog.querySelector("form");
      const attendance = range.querySelector("[data-attendance-id]");
      form.hours_change_request_date.value = range.dataset.startDate;
      form.hours_change_request_attendance_id.value =
        attendance.dataset.attendanceId;
      const title = dialog.querySelector("h2");
      if (this.selectTarget.value === "Horas extra") {
        form.hours_change_request_hour_type.value = "extra";
        title.textContent = "Editar horas extra";
      } else {
        form.hours_change_request_hour_type.value = "discount";
        title.textContent = "Editar horas de descuento";
      }
    }
    dialog.showModal();
  }

  toggleEdit(event) {
    const button = event.currentTarget;
    const dot = button.querySelector(".dot-js");
    if (button.toggleAttribute("active")) {
      this.attendancesContainerTarget.classList.add("cursor-pointer");
      this.attendancesContainerTarget.classList.add("animate-pulse-light");
      dot.classList.add("animate-ping");
      dot.classList.add("opacity-100");
      dot.classList.remove("opacity-0");
      this.attendancesContainerTarget.addEventListener(
        "click",
        this.editListener
      );
    } else {
      this.attendancesContainerTarget.classList.remove("cursor-pointer");
      this.attendancesContainerTarget.classList.remove("animate-pulse-light");
      dot.classList.remove("animate-ping");
      dot.classList.add("opacity-0");
      dot.classList.remove("opacity-100");
      this.attendancesContainerTarget.removeEventListener(
        "click",
        this.editListener
      );
    }
  }

  changeView(event) {
    if (event.target.value === "Asistencia diaria") {
      this.displayAttendance("content");
      this.displayTitle("attendance");
      this.displayCount("attendance");
      this.displayColored();
    } else if (event.target.value === "Horas extra") {
      this.displayAttendance("extraHours");
      this.displayCount("extraHours");
      this.displayTitle("extraHours");
      this.hideColored();
    } else if (event.target.value === "Horas de descuento") {
      this.displayAttendance("discountHours");
      this.displayCount("discountHours");
      this.displayTitle("discountHours");
      this.hideColored();
    }
  }

  displayTitle(type) {
    this.titleTargets.forEach((title) => {
      title.textContent = title.dataset[type];
    });
  }

  displayCount(type) {
    this.countTargets.forEach((count) => {
      count.textContent = count.dataset[type];
    });
  }

  displayAttendance(type) {
    this.simpleAttendanceTargets.forEach((att) => {
      const attContent = att.dataset[type];
      const container = att.closest("div[data-start-date]");
      // @attContent can be an empty string with a space, so it's a truthy value
      // This is used to mantain the container in the view, and keep it's edit capabilities. Because the listener uses event delegation and search for the container by the [data-start-date] attribute.
      // Used in attendances view in holidays and weekends
      if (attContent) {
        container.classList.remove("hidden");
        att.querySelector("p").textContent = attContent;
      } else {
        container.classList.add("hidden");
      }
    });
  }

  hideColored() {
    this.coloredAttendanceTargets.forEach((att) => {
      att.classList.add("hidden");
    });
  }

  displayColored() {
    this.coloredAttendanceTargets.forEach((att) => {
      att.classList.remove("hidden");
    });
  }
}
