import NavSteper from "./nav_steper_controller"

// Connects to data-controller="second-nav-steper"
export default class extends NavSteper {
  static values = { tab: Number };

  connect() {
    super.connect()
  }

  tabValueChanged() {
    this.element.dataset.tab = this.tabValue;
    this.element.current_tab.value = this.tabValue;
  }

  goToTab(event) {
    event.preventDefault();
    const params = new URL(event.target.href).searchParams;
    const tab = +params.get("tab");
    this.tabValue = tab;
  }
}
