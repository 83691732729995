import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="holidays"
export default class extends Controller {
  static targets = ["holidays", "holiday", "workDaysCount", "freeDaysCount"];

  // When a holiday connects to the DOM
  holidayTargetConnected(currentHoliday) {
    this.placeHoliday(currentHoliday);
    this.freeDaysCountTargets.forEach((counter) => {
      this.increaseCount(counter);
    });
    if (currentHoliday.dataset.weekday == "true") {
      this.workDaysCountTargets.forEach((counter) => {
        this.decreaseCount(counter);
      });
    }
  }

  // When a holiday is removed from the DOM
  holidayTargetDisconnected(currentHoliday) {
    this.freeDaysCountTargets.forEach((counter) => {
      this.decreaseCount(counter);
    });
    if (currentHoliday.dataset.weekday == "true") {
      this.workDaysCountTargets.forEach((counter) => {
        this.increaseCount(counter);
      });
    }
  }

  decreaseCount(target) {
    target.textContent = +target.textContent - 1;
  }

  increaseCount(target) {
    target.textContent = +target.textContent + 1;
  }

  countHolidays() {}

  placeHoliday(currentHoliday) {
    const holidays = this.holidayTargets;
    const nextHoliday = holidays.find(
      (holiday) => +holiday.dataset.day > +currentHoliday.dataset.day
    );

    if (nextHoliday) {
      nextHoliday.before(currentHoliday);
    } else {
      this.holidaysTarget.append(currentHoliday);
    }
  }
}
