import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="type-select"
export default class extends Controller {
  static targets = ["select", "prompt", "options"];

  connect() {
    this.display();
  }

  toggle(e) {
    e.currentTarget.toggleAttribute("open");
  }

  change(e) {
    this.selectTarget.dataset.code = e.currentTarget.dataset.code;
    this.selectTarget.value = e.currentTarget.dataset.value;

    this.selectTarget.dispatchEvent(new Event("change"));
  }

  display() {
    const code = this.selectTarget.dataset.code;
    const selected = this.optionsTarget.querySelector(`[data-code="${code}"]`);
    this.promptTarget.innerHTML = selected?.outerHTML || "-";
  }
}
