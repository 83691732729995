import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="attendance-form"
export default class extends Controller {
  static targets = ["select", "prompt", "options", "hours"];

  connect() {
    const htmlSelected = this.selectTarget.querySelector("[selected]");
    this.selectTarget.value = htmlSelected?.value;

    if (htmlSelected) {
      const selected = this.optionsTarget.querySelector(
        `div[data-value="${htmlSelected.value}"]`
      );
      this.selectTarget.dataset.color = selected.dataset.color;
      this.selectTarget.dataset.code = selected.dataset.code;
    }
    this.display();
  }

  locate(event) {
    if (event.target.closest("details").hasAttribute("open")) {
      this.optionsTarget.classList.remove("opacity-100");
    } else {
      // Positions the dropdown on top of there isn't enough space bellow
      // * Uses setTimeOut because the elements need to be visible in the DOM for it to read the height of the element
      // ! This causes a blink that is solved by a fade-in in the styles
      setTimeout(() => {
        const viewportHeight = window.innerHeight;
        const position = this.element.getBoundingClientRect();
        const height = this.optionsTarget.offsetHeight;
        if (viewportHeight - position.bottom < height) {
          this.optionsTarget.classList.add("bottom-full");
        } else {
          this.optionsTarget.classList.remove("bottom-full");
        }
        this.optionsTarget.classList.add("opacity-100");
      }, 0);
    }
  }

  permitHours() {
    const hoursFields = this.hoursTargets;
    if (this.selectTarget.dataset.code == "1") {
      hoursFields.forEach((field) => field.removeAttribute("disabled"));
    } else {
      hoursFields.forEach((field) => {
        field.setAttribute("disabled", true);
        field.value = null;
      });
    }
  }

  change(e) {
    const prevValue = this.selectTarget.value;

    this.selectTarget.value = e.currentTarget.dataset.value;
    this.selectTarget.dataset.color = e.currentTarget.dataset.color;
    this.selectTarget.dataset.code = e.currentTarget.dataset.code;

    this.selectTarget.dispatchEvent(new Event("change"));
    if (!prevValue) {
      this.dispatch("first-selection", {
        detail: { worker: this.element.dataset.worker },
      });
    }
  }

  display() {
    this.promptTarget.textContent = this.selectTarget.dataset.code || "-";
    const prevColor = this.promptTarget.parentElement.className
      .split(" ")
      .find((className) => className.startsWith("bg-"));
    this.promptTarget.parentElement.classList.remove(prevColor);
    this.promptTarget.parentElement.classList.add(
      this.selectTarget.dataset.color || "bg-white"
    );
    this.promptTarget.closest("details").removeAttribute("open");

    this.permitHours();
  }
}
