import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="accordion-tools"
export default class extends Controller {
  static targets = ['summary', 'content', 'details'];

  static animation = null;
  static isClosing = false;
  static isExpanding = false;
  static arrow = null;
  static arrowAnimation = null;

  connect() {
    this.arrow = this.summaryTarget.getElementsByTagName('svg')[0];
  }

  onClick(e) {
    e.preventDefault();
    this.detailsTarget.style.overflow = 'hidden';
    // Check if the element is being closed or is already closed
    if (this.isClosing || !this.detailsTarget.open) {
      this.open();

      // Check if the element is being openned or is already open
    } else if (this.isExpanding || this.detailsTarget.open) {
      this.shrink();
    }
  }

  shrink() {
    this.isClosing = true;

    // Store the current height of the details element
    const startHeight = `${this.detailsTarget.offsetHeight}px`;
    // Calculate the height of the summary
    const endHeight = `${this.summaryTarget.offsetHeight}px`;

    // If there is already an animation running
    if (this.animation) {
      // Cancel the current animation
      this.animation.cancel();
      this.arrowAnimation.cancel();
    }

    this.arrowAnimation = this.arrow.animate(
      {
        transform: 'rotate(90deg)',
      },
      {
        duration: 400,
        easing: 'ease-out',
      }
    );

    this.animation = this.detailsTarget.animate(
      {
        // Set the keyframes from the startHeight to endHeight
        height: [startHeight, endHeight],
      },
      {
        duration: 400,
        easing: 'ease-out',
      }
    );

    // When the animation is complete, call onAnimationFinish()
    this.animation.onfinish = () => this.onAnimationFinish(false);
    this.arrowAnimation.onfinish = () => {
      this.arrow.classList.remove('rotate-0');
      this.arrow.classList.add('rotate-90');
    };
    // If the animation is cancelled, isClosing variable is set to false
    this.animation.oncancel = () => (this.isClosing = false);
  }

  open() {
    // Apply a fixed height on the element
    this.detailsTarget.style.height = `${this.detailsTarget.offsetHeight}px`;
    // Force the [open] attribute on the details element
    this.detailsTarget.open = true;
    // Wait for the next frame to call the expand function
    window.requestAnimationFrame(() => this.expand());
  }

  expand() {
    // Set the element as "being expanding"
    this.isExpanding = true;
    // Get the current fixed height of the element
    const startHeight = `${this.detailsTarget.offsetHeight}px`;
    // Calculate the open height of the element (summary height + content height)
    const endHeight = `${
      this.summaryTarget.offsetHeight + this.contentTarget.offsetHeight
    }px`;

    // If there is already an animation running
    if (this.animation) {
      // Cancel the current animation
      this.animation.cancel();
    }

    this.arrowAnimation = this.arrow.animate(
      {
        transform: 'rotate(0deg)',
      },
      {
        duration: 400,
        easing: 'ease-out',
      }
    );

    this.animation = this.detailsTarget.animate(
      {
        // Set the keyframes from the startHeight to endHeight
        height: [startHeight, endHeight],
      },
      {
        duration: 400,
        easing: 'ease-out',
      }
    );
    // When the animation is complete, call onAnimationFinish()
    this.animation.onfinish = () => this.onAnimationFinish(true);
    this.arrowAnimation.onfinish = () => {
      this.arrow.classList.remove('rotate-90');
      this.arrow.classList.add('rotate-0');
    };
    // If the animation is cancelled, isExpanding variable is set to false
    this.animation.oncancel = () => (this.isExpanding = false);
  }

  onAnimationFinish(open) {
    // Set the open attribute based on the parameter
    this.detailsTarget.open = open;
    // Clear the stored animation
    this.animation = null;
    this.arrowAnimation = null;
    // Reset isClosing & isExpanding
    this.isClosing = false;
    this.isExpanding = false;
    // Remove the overflow hidden and the fixed height
    this.detailsTarget.style.height = this.detailsTarget.style.overflow = '';
  }
}
