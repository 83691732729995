import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="vacation-request-form"
export default class extends Controller {
  initialize() {
    this.submit = this.submit.bind(this);
    this.updateEndDate = this.updateEndDate.bind(this);
  }

  debounceSubmit(event) {
    if (event.target.value === "") return;

    clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      this.submit(event);
    }, 1000);
  }

  submit(event) {
    // this is trigered when the user types in the rut or name field or selects a date
    // return if the user selects "Canje" as vacation type and its triggered by the start_date field
    if (
      this.element.vacation_vacation_type_canje?.checked &&
      event.target.id === "vacation_start_date"
    ) {
      return;
    }

    this.element.action = "/vacations/get_data";
    this.element.vacation_worker_id.value = null;
    if (event.target.id === "vacation_rut") {
      this.element.vacation_name.value = null;
    } else if (event.target.id === "vacation_name") {
      this.element.vacation_rut.value = null;
    }
    this.element.requestSubmit();
  }

  selectVacationType(event) {
    // this is trigered when the user selects a vacation type from the radio buttons
    // add a listener to the start_date field to update the end_date field if the user selects "Canje" as vacation type else remove the listener
    if (event.target.value !== "Canje") {
      this.element.vacation_start_date.removeEventListener(
        "change",
        this.updateEndDate
      );
      // activate the end_date field
      this.element.vacation_end_date.disabled = false;
      // disable the days field
      this.element.vacation_days.disabled = true;
    } else {
      this.element.vacation_start_date.addEventListener(
        "change",
        this.updateEndDate
      );

      // disable the end_date field
      this.element.vacation_end_date.disabled = true;
      // activate the days field
      this.element.vacation_days.disabled = false;
    }
  }

  updateEndDate(event) {
    // this is trigered when the user selects a start_date
    // set the end_date field to the same date as the start_date field
    this.element.vacation_end_date.value = event.target.value;
  }
}
