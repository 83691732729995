import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="conditional-display"
export default class extends Controller {
  static targets = ["check", "select", "toDisplay"];

  toDisplayTargetConnected(target) {
    if (this.hasSelectTarget) {
      this.isSelected(target);
    } else {
      this.isChecked(target);
    }
  }

  select() {
    this.toDisplayTargets.forEach(this.isSelected.bind(this));
  }

  check() {
    this.toDisplayTargets.forEach(this.isChecked.bind(this));
  }

  isChecked(target) {
    const checked = this.checkTargets.find((check) => check.checked);
    if (target.dataset.values.split(" ").includes(checked?.value)) {
      target.setAttribute("open", true);
    } else {
      target.removeAttribute("open");
    }
  }

  isSelected(target) {
    if (
      target.dataset.values
        .split(" ")
        .includes(this.selectTarget.value.replaceAll(" ", "_"))
    ) {
      target.setAttribute("open", true);
    } else {
      target.removeAttribute("open");
    }
  }
}
